import { useEffect, useRef, useState } from "react"
import { Flip, Slide } from "react-reveal"
import SliderAnimation from "../Components/SliderAnimation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faCode,
	faExternalLink,
	faFileText,
	faUserAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { useMain } from "../Components/MainComponent"

const Portfolio = () => {
	const getHoverDirection = (event) => {
		var directions = ["top", "right", "bottom", "left"]
		var item = event.currentTarget
		var w = item.offsetWidth
		var h = item.offsetHeight
		var x =
			(event.clientX - item.getBoundingClientRect().left - w / 2) *
			(w > h ? h / w : 1)
		var y =
			(event.clientY - item.getBoundingClientRect().top - h / 2) *
			(h > w ? w / h : 1)
		var d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4
		return directions[d]
	}
	const { currentSlideShow, setCurrentSlideShow } = useMain()
	const prevSlideShow = useRef(currentSlideShow)
	const [nextSlideShow, setNextSlideShow] = useState(currentSlideShow)
	const [slideShowOpen, setSlideShowOpen] = useState(false)
	const [sliderDirection, setSliderDirection] = useState("right")
	const prevImage = useRef(-1)

	useEffect(() => {
		if (currentSlideShow > -1) {
			setSlideShowOpen(true)
		}
		if (currentSlideShow === -1 && nextSlideShow === -1) {
			prevSlideShow.current = -1
			setNextSlideShow(-1)
		}
		if (prevSlideShow.current !== nextSlideShow) {
			setSlideShowOpen(false)
			setTimeout(() => {
				setCurrentSlideShow(nextSlideShow)
			}, 500)
		}
		if (nextSlideShow === currentSlideShow) {
			setSlideShowOpen(true)
		}
		prevSlideShow.current = currentSlideShow
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSlideShow, nextSlideShow])

	const [portfolios, setPortfolios] = useState([
		{
			title: "To Do App",
			img: [
				{
					primary: true,
					src: require("../Assets/img/projects/project1/1.png"),
					active: true,
				},
				{
					src: require("../Assets/img/projects/project1/2.png"),
					active: false,
				},
				{
					src: require("../Assets/img/projects/project1/3.png"),
					active: false,
				},
				{
					src: require("../Assets/img/projects/project1/4.png"),
					active: false,
				},
			],
			type: "Website",
			client: "Personal",
			techno: "React.js, Node.js",
			preview: "https://todo.tdem.ir/",
			github: "https://github.com/AmirrezaM-dev/Todo-App",
			hover: false,
		},
		{
			title: "Old Personal Website",
			img: [
				{
					primary: true,
					src: require("../Assets/img/projects/project3/1.png"),
					active: true,
				},
				{
					src: require("../Assets/img/projects/project3/2.png"),
					active: false,
				},
				{
					src: require("../Assets/img/projects/project3/3.png"),
					active: false,
				},
				{
					src: require("../Assets/img/projects/project3/4.png"),
					active: false,
				},
			],
			type: "Website",
			client: "Personal",
			techno: "React.js",
			preview: "https://personal.tdem.ir",
			github: "https://github.com/AmirrezaM-dev/Old-Personal-Website",
			hover: false,
		},
	])

	const [sliderDragXStart, setSliderDragXStart] = useState({ clientX: 0 })
	const [sliderDragXEnd, setSliderDragXEnd] = useState({ clientX: 0 })
	useEffect(() => {
		if (sliderDragXStart && sliderDragXEnd)
			if (currentSlideShow > -1 && portfolios[currentSlideShow]) {
				let clientStartX, clientEndX
				if (sliderDragXStart.clientX && sliderDragXEnd.clientX) {
					clientStartX = sliderDragXStart.clientX
					clientEndX = sliderDragXEnd.clientX
				} else if (
					sliderDragXStart.touches[0] &&
					sliderDragXEnd.changedTouches[0] &&
					sliderDragXStart.touches[0].clientX &&
					sliderDragXEnd.changedTouches[0].clientX
				) {
					clientStartX = sliderDragXStart.touches[0].clientX
					clientEndX = sliderDragXEnd.changedTouches[0].clientX
				}
				setPortfolios([
					...portfolios.map((portfolio, i) => {
						let nextActiveImg
						portfolio.img.filter(
							(img, imgIndex) =>
								(nextActiveImg = img.active
									? clientStartX > clientEndX
										? imgIndex + 1 >= portfolio.img.length
											? 0
											: imgIndex + 1
										: imgIndex - 1 >= 0
										? imgIndex - 1
										: portfolio.img.length - 1
									: nextActiveImg)
						)
						return i === currentSlideShow
							? {
									...portfolio,
									img: [
										...portfolio.img.map(
											(img, imgIndex) => {
												return imgIndex ===
													nextActiveImg
													? {
															...img,
															active: true,
													  }
													: {
															...img,
															active: false,
													  }
											}
										),
									],
							  }
							: portfolio
					}),
				])
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sliderDragXEnd])

	return (
		<>
			<div className="section-wrapper portfolio">
				{/* Page Title Starts */}
				<section className="title-section text-left text-sm-center">
					<h1>
						MY <span>PORTFOLIO</span>
					</h1>
					<span className="title-bg">Works</span>
				</section>
				{/* Page Title Ends */}
				{/* Main Content Starts */}
				<section className="main-content text-center">
					<div
						id="grid-gallery"
						className={`min-vh-100 container grid-gallery px-5 ${
							currentSlideShow > -1 ? "slideshow-open" : ""
						}`}
					>
						{/* Portfolio Grid Starts */}
						<section className="grid-wrap">
							<ul className="row grid">
								{/* Portfolio Items Starts */}
								{portfolios.map((portfolio, portfolioIndex) => {
									return (
										<li
											key={portfolioIndex}
											onMouseEnter={(e) =>
												setPortfolios(
													portfolios.map(
														(value, index) => {
															if (
																index ===
																portfolioIndex
															)
																return {
																	...value,
																	hover: true,
																	direction:
																		getHoverDirection(
																			e
																		),
																}
															return {
																...value,
																hover: false,
															}
														}
													)
												)
											}
											onMouseLeave={(e) =>
												setPortfolios(
													portfolios.map(
														(value, index) => {
															if (
																index ===
																portfolioIndex
															)
																return {
																	...value,
																	direction:
																		getHoverDirection(
																			e
																		),
																	hover: false,
																}
															return value
														}
													)
												)
											}
											onClick={() => {
												setCurrentSlideShow(
													portfolioIndex
												)
												setPortfolios(
													portfolios.map(
														(value, index) => {
															if (
																index ===
																portfolioIndex
															)
																return {
																	...value,
																	slideshowOpen: true,
																}
															return value
														}
													)
												)
											}}
										>
											<figure>
												<img
													src={
														portfolio.img.filter(
															(img) => img.primary
														).length
															? portfolio.img.filter(
																	(img) =>
																		img.primary
															  )[0].src
															: ""
													}
													alt=""
												/>
												<Slide
													duration={500}
													top={
														portfolio.direction ===
														"top"
													}
													bottom={
														portfolio.direction ===
														"bottom"
													}
													left={
														portfolio.direction ===
														"left"
													}
													right={
														portfolio.direction ===
														"right"
													}
													when={portfolio.hover}
												>
													<div>
														<span>
															{portfolio.title}
														</span>
													</div>
												</Slide>
											</figure>
										</li>
									)
								})}
								{/* Portfolio Items End */}
							</ul>
						</section>
						{/* Portfolio Grid Ends */}
						{/* Portfolio Details Starts */}
						<section className="slideshow">
							<ul>
								{/* Portfolio Item Detail Starts */}
								{currentSlideShow > -1 ? (
									<SliderAnimation
										prevSlideShow={prevSlideShow}
										sliderDirection={sliderDirection}
										when={
											currentSlideShow > -1 &&
											slideShowOpen
										}
									>
										<li className="show current">
											<figure>
												{/* Project Details Starts */}
												<figcaption>
													<h3>
														{
															portfolios[
																currentSlideShow
															].title
														}
													</h3>
													<div className="row open-sans-font">
														<div className="col-12 col-sm-6 mb-2">
															<FontAwesomeIcon
																icon={
																	faFileText
																}
																className="pe-2"
															/>
															<span className="project-label">
																Type
															</span>
															:
															<span className="ft-wt-600 uppercase">
																&nbsp;
																{
																	portfolios[
																		currentSlideShow
																	].type
																}
															</span>
														</div>
														<div className="col-12 col-sm-6 mb-2">
															<FontAwesomeIcon
																icon={faUserAlt}
																className="pe-2"
															/>
															<span className="project-label">
																Client
															</span>
															:
															<span className="ft-wt-600 uppercase">
																&nbsp;
																{
																	portfolios[
																		currentSlideShow
																	].client
																}
															</span>
														</div>
														<div className="col-12 col-sm-6 mb-2">
															<FontAwesomeIcon
																icon={faCode}
																className="pe-2"
															/>
															<span className="project-label">
																Technologies
															</span>
															:
															<span className="ft-wt-600 uppercase">
																&nbsp;
																{
																	portfolios[
																		currentSlideShow
																	].techno
																}
															</span>
														</div>
														<div className="col-12 col-sm-6 mb-2">
															<FontAwesomeIcon
																icon={
																	faExternalLink
																}
																className="pe-2"
															/>
															<span className="project-label ft-wt-600 uppercase">
																<a
																	href={
																		portfolios[
																			currentSlideShow
																		]
																			.preview
																	}
																	rel={
																		"noreferrer"
																	}
																	target="_blank"
																>
																	Preview
																</a>
															</span>
														</div>
														{portfolios[
															currentSlideShow
														].github ? (
															<div className="col-12 col-sm-6 mb-2">
																<FontAwesomeIcon
																	icon={
																		faGithub
																	}
																	className="pe-2"
																/>
																<span className="project-label ft-wt-600 uppercase">
																	<a
																		href={
																			portfolios[
																				currentSlideShow
																			]
																				.github
																		}
																		rel={
																			"noreferrer"
																		}
																		target="_blank"
																	>
																		GitHub
																	</a>
																</span>
															</div>
														) : (
															<></>
														)}
													</div>
												</figcaption>
												{/* Project Details Ends */}
												{/* Main Project Content Starts */}
												<div
													id="slider"
													className="carousel slide portfolio-slider"
													data-ride="carousel"
													data-interval="false"
												>
													<ol className="carousel-indicators">
														{portfolios[
															currentSlideShow
														].img.map(
															(img, index) => {
																return (
																	<li
																		key={
																			index
																		}
																		className={`${
																			img.active
																				? "active"
																				: ""
																		}`}
																		onClick={() =>
																			setPortfolios(
																				portfolios.map(
																					(
																						portfolio,
																						portfolioIndex
																					) => {
																						if (
																							portfolioIndex ===
																							currentSlideShow
																						) {
																							return {
																								...portfolio,
																								img: portfolio.img.map(
																									(
																										portfolioImg,
																										portfolioImgIndex
																									) => {
																										return {
																											...portfolioImg,
																											active:
																												portfolioImgIndex ===
																												index,
																										}
																									}
																								),
																							}
																						} else {
																							return portfolio
																						}
																					}
																				)
																			)
																		}
																	/>
																)
															}
														)}
													</ol>
													{/* The slideshow */}
													<div className="carousel-inner">
														{portfolios[
															currentSlideShow
														].img.map(
															(img, index) => {
																return (
																	<Flip
																		top
																		when={
																			img.active
																		}
																		onReveal={() => {
																			prevImage.current =
																				index
																		}}
																		key={
																			index
																		}
																	>
																		<div
																			className={`carousel-item ${
																				img.active
																					? "active"
																					: ""
																			}`}
																			draggable={
																				true
																			}
																			onDragStart={(
																				e
																			) => {
																				setSliderDragXStart(
																					e
																				)
																			}}
																			onTouchStart={(
																				e
																			) => {
																				setSliderDragXStart(
																					e
																				)
																			}}
																			onDragEnd={(
																				e
																			) => {
																				setSliderDragXEnd(
																					e
																				)
																			}}
																			onTouchEnd={(
																				e
																			) => {
																				setSliderDragXEnd(
																					e
																				)
																			}}
																		>
																			<img
																				src={
																					img.src
																				}
																				alt=""
																			/>
																		</div>
																	</Flip>
																)
															}
														)}
													</div>
												</div>
												{/* Main Project Content Ends */}
											</figure>
										</li>
									</SliderAnimation>
								) : (
									<></>
								)}
								{/* Portfolio Item Detail Ends */}
							</ul>
							{/* Portfolio Navigation Starts */}
							<nav>
								<span
									className="icon nav-prev"
									onClick={() => {
										if (currentSlideShow > 0) {
											setNextSlideShow(
												currentSlideShow - 1
											)
											setSliderDirection("left")
										} else {
											setSlideShowOpen(false)
											setTimeout(() => {
												setNextSlideShow(-1)
												setCurrentSlideShow(-1)
											}, 500)
										}
									}}
								>
									<img
										src={require("../Assets/img/projects/navigation/left-arrow.png")}
										alt="previous"
									/>
								</span>
								<span
									className="icon nav-next"
									onClick={() => {
										if (portfolios[currentSlideShow + 1]) {
											setSliderDirection("right")
											setNextSlideShow(
												currentSlideShow + 1
											)
										} else {
											setSlideShowOpen(false)
											setTimeout(() => {
												setCurrentSlideShow(-1)
												setNextSlideShow(-1)
											}, 500)
										}
									}}
								>
									<img
										src={require("../Assets/img/projects/navigation/right-arrow.png")}
										alt="next"
									/>
								</span>
								<span
									className="nav-close"
									onClick={() => {
										setCurrentSlideShow(-1)
										setNextSlideShow(-1)
									}}
								>
									<img
										src={require("../Assets/img/projects/navigation/close-button.png")}
										alt="close"
									/>
								</span>
							</nav>
							{/* Portfolio Navigation Ends */}
						</section>
					</div>
				</section>
				{/* Main Content Ends */}
			</div>
		</>
	)
}

export default Portfolio
