import { Suspense, lazy, useEffect, useMemo, useState } from "react"
import Header from "./Components/Header"
import Preloader from "./Components/Preloader"
import Home from "./Pages/Home"
import About from "./Pages/About"
import Portfolio from "./Pages/Portfolio"
import {
	faBriefcase,
	faEnvelopeOpen,
	faHouse,
	faUser,
} from "@fortawesome/free-solid-svg-icons"
import Contact from "./Pages/Contact"
import { Route, Routes, useLocation } from "react-router-dom"

const MainComponent = lazy(() => import("./Components/MainComponent"))

const App = () => {
	const Navigations = useMemo(
		() => [
			{
				title: "Home",
				faIcon: faHouse,
				path: "/",
				element: () => {
					return <Home />
				},
			},
			{
				title: "About",
				faIcon: faUser,
				path: "/about",
				element: () => {
					return <About />
				},
			},
			{
				title: "Portfolio",
				faIcon: faBriefcase,
				path: "/portfolio",
				element: () => {
					return <Portfolio />
				},
			},
			{
				title: "Contact",
				faIcon: faEnvelopeOpen,
				path: "/contact",
				element: () => {
					return <Contact />
				},
			},
		],
		[]
	)
	const location = useLocation()

	const [displayLocation, setDisplayLocation] = useState(location)
	const [transitionStage, setTransistionStage] = useState("fadeInNext")

	useEffect(() => {
		if (location !== displayLocation) {
			setTransistionStage(
				Navigations.findIndex(
					(val) => val.path === displayLocation.pathname
				) <
					Navigations.findIndex(
						(val) => val.path === location.pathname
					)
					? "fadeOutNext"
					: "fadeOutPrevious"
			)
		}
		setTimeout(() => {
			window.scroll(0, 0)
		}, 500)
	}, [location, displayLocation, Navigations])

	return (
		<Suspense fallback={<Preloader />}>
			<MainComponent Navigations={Navigations}>
				<Preloader off />
				<Header />
				<div
					className={`${transitionStage}`}
					onAnimationEnd={() => {
						if (transitionStage === "fadeOutPrevious") {
							setTransistionStage("fadeInPrevious")
							setDisplayLocation(location)
						} else if (transitionStage === "fadeOutNext") {
							setTransistionStage("fadeInNext")
							setDisplayLocation(location)
						}
					}}
				>
					<Routes location={displayLocation}>
						{Navigations.map((val, i) => {
							return (
								<Route
									key={i}
									path={val.path}
									element={val.element(0)}
								/>
							)
						})}
					</Routes>
				</div>
			</MainComponent>
		</Suspense>
	)
}

export default App
