import {
	faBriefcase,
	faCertificate,
	faDownload,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const About = () => {
	const Boxes = [
		{ titleOne: "experience", titleTwo: "years of", value: 10 },
		{ titleOne: "completed", titleTwo: "projects", value: 100 },
		{ titleOne: "Happy", titleTwo: "customers", value: 100 },
		{ titleOne: "Programming", titleTwo: "languages", value: 10 },
	]
	const getAge = (dateString) => {
		var today = new Date()
		var birthDate = new Date(dateString)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		return age
	}
	return (
		<>
			<div className="section-wrapper about">
				{/* Page Title Starts */}
				<section className="title-section text-left text-sm-center">
					<h1>
						ABOUT <span>ME</span>
					</h1>
					<span className="title-bg">Resume</span>
				</section>
				{/* Page Title Ends */}
				{/* Main Content Starts */}
				<section className="main-content text-center">
					<Container
						as={Row}
						className="text-center p-0 m-0 mx-auto justify-content-center"
					>
						{/* <Row className="justify-content-md-center"> */}
						{/* Personal Info Starts */}
						<Col xs={12} lg={5} xl={6} as={Row}>
							<Col sm={12} className="text-center">
								<h3 className="text-uppercase custom-title mb-0 ft-wt-600 text-center">
									Personal Information
								</h3>
							</Col>
							<Col sm={12} className="d-block d-sm-none">
								<img
									src={require("../Assets/img/img-mobile.jpg")}
									className="img-fluid main-img-mobile"
									alt=""
								/>
							</Col>
							<Col xs={6} className="text-end">
								<ul className="about-list list-unstyled open-sans-font">
									<li>
										<span className="title">
											first name :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											Amirreza
										</span>
									</li>
									<li>
										<span className="title">
											last name :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											Malaki
										</span>
									</li>
									<li>
										<span className="title">Age :</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											{getAge("2001/05/28")}
										</span>
									</li>
									<li>
										<span className="title">
											Nationality :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											Iranian
										</span>
									</li>
									<li>
										<span className="title">
											Freelance :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											Available
										</span>
									</li>
								</ul>
							</Col>
							<Col xs={6} className="text-start">
								<ul className="about-list list-unstyled open-sans-font">
									<li>
										<span className="title">Address :</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											UAE/Dubai
										</span>
									</li>
									<li>
										<span className="title">phone :</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											+971588517790
										</span>
									</li>
									<li>
										<span className="title">Email :</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											amir001mlk@gmail.com
										</span>
									</li>
									<li>
										<span className="title">
											Linked In :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											<a
												href="https://www.linkedin.com/in/amirreza-malaki-859513263/"
												target="_blink"
											>
												Amirreza Malaki
											</a>
										</span>
									</li>
									<li>
										<span className="title">
											languages :
										</span>
										<span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
											English, Persian, Turkish
										</span>
									</li>
								</ul>
							</Col>
							<Col sm={12} className="mt-3 mb-5 text-center">
								<a className="button" href="/">
									<span className="button-text">
										Download CV
									</span>
									<FontAwesomeIcon
										className="button-icon"
										icon={faDownload}
									/>
								</a>
							</Col>
						</Col>

						{/* Personal Info Ends */}
						{/* Boxes Starts */}
						<Col
							xs={12}
							lg={7}
							xl={6}
							as={Row}
							className="mt-5 mt-lg-0"
						>
							{Boxes.map((start, i) => {
								return (
									<Col key={i} xs={6} className="text-center">
										<div className="box-stats with-margin">
											<h3 className="poppins-font position-relative">
												{start.value}
											</h3>
											<p className="open-sans-font m-0 position-relative text-uppercase p-sm-0">
												{start.titleOne}
												<span className="d-block">
													{start.titleTwo}
												</span>
											</p>
										</div>
									</Col>
								)
							})}
						</Col>
						{/* Boxes Ends */}
						{/* </Row> */}
						<hr className="separator mt-4 border-secondary" />
						{/* Skills Starts */}
						<Row>
							<Col sm={12}>
								<h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
									My Skills
								</h3>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p100">
									<span>100%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									html
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p100">
									<span>100%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									javascript
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p100">
									<span>100%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									css
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p80">
									<span>80%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									php
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p95">
									<span>95%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									react.js
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p95">
									<span>95%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									Node.js/Express.js
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p65">
									<span>65%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									Electron.js
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p80">
									<span>80%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									bootstrap
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p95">
									<span>95%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									MongoDB
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p60">
									<span>60%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									Laravel
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p100">
									<span>100%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									MySQL/SQL
								</h6>
							</Col>
							<Col xs={6} md={3} lg={2} className="mb-3 mb-sm-5">
								<div className="c100 p10">
									<span>10%</span>
									<div className="slice">
										<div className="bar" />
										<div className="fill" />
									</div>
								</div>
								<h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
									React Native
								</h6>
							</Col>
						</Row>
						{/* Skills Ends */}
						<hr className="separator mt-4 border-secondary" />
						{/* Experience & Education Starts */}
						<Row className="mx-0 text-start">
							<Col sm={12}>
								<h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
									Experience <span>&amp;</span>
									Certification
								</h3>
							</Col>
							<Col lg={6} className="m-15px-tb">
								<div className="resume-box">
									<ul>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faBriefcase}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												2020 - 2022
											</span>
											<h5 className="poppins-font text-uppercase">
												Full stack developer
												<span className="place open-sans-font">
													Upland Group
												</span>
											</h5>
											<p className="open-sans-font">
												I upgraded their current website
												and developed a new one with a
												modern design.
												<br />I developed multiple
												websites for Upland Group
												clients and for the Upland Group
												company itself.
											</p>
										</li>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faBriefcase}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												2017 - 2019
											</span>
											<h5 className="poppins-font text-uppercase">
												Software Engineer
												<span className="place open-sans-font">
													AnitqBar
												</span>
											</h5>
											<p className="open-sans-font">
												I developed a point-of-sale
												system with a local server, tax
												calculator, receipt generator,
												and ordering system, which is
												available for use with any
												device.
												<br />I developed a dashboard to
												control the summary of
												everything with a professional
												filtering system that can filter
												for specific dates or events.
											</p>
										</li>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faBriefcase}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												2015 - 2018
											</span>
											<h5 className="poppins-font text-uppercase">
												Freelancer
												<span className="place open-sans-font">
													Ponisha.ir
												</span>
											</h5>
											<p className="open-sans-font">
												Full stack web development for
												multiple clients.
											</p>
										</li>
									</ul>
								</div>
							</Col>
							<Col lg={6} className="m-15px-tb">
								<div className="resume-box">
									<ul>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faCertificate}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												Alison
											</span>
											<h5 className="poppins-font">
												React.js
											</h5>
											<p className="open-sans-font">
												Reat is an open-source
												JavaScript lcibrary created by
												Meta.
												<br />
												<a
													href="https://alison.com/certification/check/2y10i7fCQVmTd9TtegqRXtRmlxnp537dojLeLQonqhSO37TwiDBww3O"
													target="_blank"
													rel="noreferrer"
												>
													Show credential
												</a>
											</p>
										</li>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faCertificate}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												Alison
											</span>
											<h5 className="poppins-font">
												E-Commerce
											</h5>
											<p className="open-sans-font">
												Diploma in E-Commerce Web
												Development.
												<br />
												<a
													href="https://alison.com/certification/check/2y10ZaTiCwVJgpwZTyeYCzmMf8bBL8dKQkCIznOBVj33pgCksJmtK"
													target="_blank"
													rel="noreferrer"
												>
													Show credential
												</a>
											</p>
										</li>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faCertificate}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												Alison
											</span>
											<h5 className="poppins-font">
												Web Development
											</h5>
											<p className="open-sans-font">
												Diploma in Application
												Development Using PHP and MySQL.
												<br />
												<a
													href="https://alison.com/certification/check/2y100mKgYOwrS66nlbj0Iil6emAxqd25WoUZy8ClAT30MIhzX3jlm"
													target="_blank"
													rel="noreferrer"
												>
													Show credential
												</a>
											</p>
										</li>
										<li>
											<div className="icon">
												<FontAwesomeIcon
													icon={faCertificate}
												/>
											</div>
											<span className="time open-sans-font text-uppercase">
												Alison
											</span>
											<h5 className="poppins-font">
												Web Development
											</h5>
											<p className="open-sans-font">
												Diploma in HTML5, CSS3 and
												JavaScript.
												<br />
												<a
													href="https://alison.com/certification/check/$2y$10$K3uBZu3vT28ZaJ7AhnoOieVuntwMDmXIlrogCujhXR1ZKSaHfcswS"
													target="_blank"
													rel="noreferrer"
												>
													Show credential
												</a>
											</p>
										</li>
										{/* <li>
												<div className="icon">
													<FontAwesomeIcon
														icon={faCertificate}
													/>
												</div>
												<span className="time open-sans-font text-uppercase">
													Alison
												</span>
												<h5 className="poppins-font">
													Web Development
												</h5>
												<p className="open-sans-font">
													Diploma in Website
													Development Using HTML and
													CSS
													<br />
													<a
														href="https://alison.com/certification/check/$2y$10$2aoCPXY5KFyZcJZhV7k6uAJlcfO6gsu2ws3SaKVSLTEmj8StioBK"
														target="_blank"
														rel="noreferrer"
													>
														Show credential
													</a>
												</p>
											</li>
											<li>
												<div className="icon">
													<FontAwesomeIcon
														icon={faCertificate}
													/>
												</div>
												<span className="time open-sans-font text-uppercase">
													Alison
												</span>
												<h5 className="poppins-font">
													CSS
												</h5>
												<p className="open-sans-font">
													Diploma in Cascading Style
													Sheets using HTML.
													<br />
													<a
														href="https://alison.com/certification/check/2y108qmADt1g7B8EaKyf4OYDCO2rj6K4Qccq7Vt26vXk0v8ZQ4XkpGm"
														target="_blank"
														rel="noreferrer"
													>
														Show credential
													</a>
												</p>
											</li> */}
									</ul>
								</div>
							</Col>
						</Row>
						{/* Experience & Education Ends */}
					</Container>
				</section>
				{/* Main Content Ends */}
			</div>
		</>
	)
}

export default About
