import {
	faCheck,
	faClose,
	faEnvelope,
	faEnvelopeOpen,
	faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { Form, Spinner } from "react-bootstrap"
import axios from "axios"
import { useRef, useState } from "react"
import { Fade } from "react-reveal"
const Contact = () => {
	const formRef = useRef({})
	formRef.fullName = useRef()
	formRef.email = useRef()
	formRef.title = useRef()
	formRef.message = useRef()
	const [sending, setSending] = useState(false)
	const [sent, setSent] = useState(false)
	const [failed, setFailed] = useState(false)
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		title: "",
		message: "",
	})
	const axiosApi = axios.create({
		baseURL: "https://telegram-bot-server-app.onrender.com/",
	})
	let timeOutCleanUp
	const onSubmit = (e) => {
		e.preventDefault()
		if (
			formData.fullName.length &&
			formData.email.length &&
			formData.title.length &&
			formData.message.length
		) {
			clearInterval(timeOutCleanUp)
			setSending(true)
			axiosApi
				.post(`/api/telegram/sendMessage/5415479548`, formData)
				.then((data) => {
					if (data.data.message && data.data.message === "sent") {
						setSent(true)
						setFormData({
							fullName: "",
							email: "",
							title: "",
							message: "",
						})
					} else {
						//error
					}
				})
				.catch((e) => {
					setFailed(true)
				})
				.finally(() => {
					setSending(false)
					timeOutCleanUp = setTimeout(() => {
						setSent(false)
						setFailed(false)
					}, 2000)
				})
		} else {
			!formData.fullName.length ? (
				formRef.fullName.current.focus()
			) : !formData.email.length ? (
				formRef.email.current.focus()
			) : !formData.title.length ? (
				formRef.title.current.focus()
			) : !formData.message.length ? (
				formRef.message.current.focus()
			) : (
				<></>
			)
		}
	}
	return (
		<>
			<div className="section-wrapper contact">
				{/* Page Title Starts */}
				<section className="title-section text-left text-sm-center">
					<h1>
						get in <span>touch</span>
					</h1>
					<span className="title-bg">contact</span>
				</section>
				{/* Page Title Ends */}
				{/* Main Content Starts */}
				<section className="main-content">
					<Container className="px-5">
						<Row className="px-5">
							{/* Left Side Starts */}
							<Col sm={12} lg={4}>
								<h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
									Don't be shy !
								</h3>
								<p className="open-sans-font mb-3">
									Feel free to get in touch with me. I am
									always open to discussing new projects,
									creative ideas or opportunities to be part
									of your visions.
								</p>
								<p className="open-sans-font custom-span-contact position-relative">
									<i className="position-absolute">
										<FontAwesomeIcon
											icon={faEnvelopeOpen}
										/>
									</i>
									<span className="d-block">Mail Me</span>
									amir001mlk@gmail.com
								</p>
								<p className="open-sans-font custom-span-contact position-relative">
									<i className="position-absolute">
										<FontAwesomeIcon icon={faPhoneSquare} />
									</i>
									<span className="d-block">call me</span>
									+971 588517790
								</p>
								<ul className="social list-unstyled pt-1 mb-5">
									<li className="LinkedIn">
										<a
											href="https://www.linkedin.com/in/amirreza-malaki-859513263/"
											target="_blank"
											rel="noreferrer"
											title="LinkedIn"
										>
											<FontAwesomeIcon
												icon={faLinkedinIn}
											/>
										</a>
									</li>
									<li className="Youtube">
										<a
											href="https://github.com/AmirrezaM-dev"
											target="_blank"
											rel="noreferrer"
											title="Youtube"
										>
											<FontAwesomeIcon icon={faGithub} />
										</a>
									</li>
								</ul>
							</Col>
							{/* Left Side Ends */}
							{/* Contact Form Starts */}
							<Col sm={12} lg={8}>
								<Form
									className="contactform"
									onSubmit={onSubmit}
								>
									<div className="contactform">
										<div className="row">
											<div className="col-12 col-md-4">
												<input
													ref={formRef.fullName}
													type="text"
													name="name"
													onChange={(e) =>
														setFormData({
															...formData,
															fullName:
																e.target.value,
														})
													}
													value={formData.fullName}
													disabled={sending}
													placeholder="YOUR NAME"
												/>
											</div>
											<div className="col-12 col-md-4">
												<input
													type="email"
													name="email"
													ref={formRef.email}
													onChange={(e) =>
														setFormData({
															...formData,
															email: e.target
																.value,
														})
													}
													value={formData.email}
													disabled={sending}
													placeholder="YOUR EMAIL"
												/>
											</div>
											<div className="col-12 col-md-4">
												<input
													type="text"
													name="subject"
													ref={formRef.title}
													onChange={(e) =>
														setFormData({
															...formData,
															title: e.target
																.value,
														})
													}
													value={formData.title}
													disabled={sending}
													placeholder="YOUR SUBJECT"
												/>
											</div>
											<div className="col-12">
												<textarea
													name="message"
													ref={formRef.message}
													placeholder="YOUR MESSAGE"
													onChange={(e) =>
														setFormData({
															...formData,
															message:
																e.target.value,
														})
													}
													value={formData.message}
													disabled={sending}
												/>
											</div>
											<div className="col-12 text-center mx-0 px-0">
												<button
													type="submit"
													className="button"
													disabled={sending}
												>
													<span className="button-text">
														Send Message
													</span>
													<FontAwesomeIcon
														className="button-icon"
														icon={faEnvelope}
													/>
												</button>
											</div>
											<Fade when={sent}>
												<div
													className={`col-12 form-message ${
														!sent ? "d-none" : ""
													}`}
												>
													<span className="output_message text-center font-weight-600 text-uppercase">
														Your message has been
														sent
														<br />
														<FontAwesomeIcon
															icon={faCheck}
														/>
													</span>
												</div>
											</Fade>
											<Fade when={failed}>
												<div
													className={`col-12 form-message ${
														!failed ? "d-none" : ""
													}`}
												>
													<span className="output_message text-center font-weight-600 text-uppercase">
														Unfortunately, your
														message has not sent
														please try again later.
														<br />
														<FontAwesomeIcon
															icon={faClose}
														/>
													</span>
												</div>
											</Fade>
											<Fade when={sending}>
												<div
													className={`col-12 form-message ${
														!sending ? "d-none" : ""
													}`}
												>
													<span className="output_message text-center font-weight-600 text-uppercase">
														Sending your message
														<br />
														<Spinner
															animation="border"
															role="status"
														>
															<span className="visually-hidden">
																Loading...
															</span>
														</Spinner>
													</span>
												</div>
											</Fade>
										</div>
									</div>
								</Form>
							</Col>
							{/* Contact Form Ends */}
						</Row>
					</Container>
				</section>

				{/* Main Content Ends */}
			</div>
		</>
	)
}

export default Contact
