import React from "react"
import { Slide, Zoom } from "react-reveal"

const SliderAnimation = ({
	children,
	when,
	sliderDirection,
	prevSlideShow,
}) => {
	return prevSlideShow.current === -1 ? (
		<Zoom when={when} duration={500}>
			{children}
		</Zoom>
	) : (
		<Slide
			left={sliderDirection === "left"}
			right={sliderDirection === "right"}
			opposite
			when={when}
			duration={500}
		>
			{children}
		</Slide>
	)
}

export default SliderAnimation
