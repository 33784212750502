import React from "react"

const Preloader = ({ off }) => {
	return (
		<div className={`preloader ${off ? "off" : ""}`}>
			<div className="black_wall"></div>
			<div className="loader"></div>
		</div>
	)
}

export default Preloader
