import ReactDOM from "react-dom/client"
import { HashRouter as Routes } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Assets/css/circle.css"
import "./Assets/css/style.css"
import "./Assets/css/skins/yellow.css"
// import "./Assets/css/skins/blue.css"
// import "./Assets/css/skins/green.css"
// import "./Assets/css/skins/yellow.css"
// import "./Assets/css/skins/blueviolet.css"
// import "./Assets/css/skins/goldenrod.css"
// import "./Assets/css/skins/magenta.css"
// import "./Assets/css/skins/orange.css"
// import "./Assets/css/skins/purple.css"
// import "./Assets/css/skins/red.css"
// import "./Assets/css/skins/yellowgreen.css"
import "./index.css"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Routes>
		<App />
	</Routes>
)
