import { Slide } from "react-reveal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import { useMain } from "./MainComponent"
import { useState } from "react"

const Header = () => {
	const [mobileNavToggled, setMobileNavToggled] = useState(false)
	const { Navigations, currentSlideShow } = useMain()
	const pathName = useLocation().pathname
	const navigate = useNavigate()
	return (
		<>
			{/* Header Starts */}
			<header className="header" id="navbar-collapse-toggle">
				{/* Fixed Navigation Starts */}
				<Slide bottom>
					<ul
						className={`icon-menu d-none ${
							currentSlideShow === -1 ? "d-lg-block" : "d-none"
						}`}
					>
						{Navigations.map((nav, index) => {
							return (
								<li
									key={index}
									className={`icon-box ${
										nav.path === pathName ||
										(pathName !== "/" &&
											nav.path.indexOf(pathName) !== -1)
											? "active"
											: ""
									}`}
									onClick={() => {
										navigate(nav.path)
									}}
								>
									<FontAwesomeIcon icon={nav.faIcon} />
									<h2>{nav.title}</h2>
								</li>
							)
						})}
					</ul>
				</Slide>
				{/* Fixed Navigation Ends */}
				{/* Mobile Menu Starts */}
				<Nav
					role="navigation"
					className={`${
						currentSlideShow === -1 ? "d-block" : "d-none"
					} d-lg-none`}
				>
					<div id="menuToggle">
						<input
							type="checkbox"
							checked={mobileNavToggled}
							onChange={(e) =>
								setMobileNavToggled(e.target.checked)
							}
						/>
						<span />
						<span />
						<span />
						<ul className="list-unstyled" id="menu">
							{Navigations.map((nav, index) => {
								return (
									<li
										key={index}
										className={`${
											nav.path === pathName ||
											(pathName !== "/" &&
												nav.path.indexOf(pathName) !==
													-1)
												? "active"
												: ""
										}`}
										onClick={() => {
											setMobileNavToggled(false)
											navigate(nav.path)
										}}
									>
										<Link>
											<FontAwesomeIcon
												icon={nav.faIcon}
											/>
											<span>{nav.title}</span>
										</Link>
									</li>
								)
							})}
						</ul>
					</div>
				</Nav>
				{/* Mobile Menu Ends */}
			</header>
			{/* Header Ends */}
		</>
	)
}

export default Header
