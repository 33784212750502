import { Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useEffect } from "react"

const Home = () => {
	const disableOverflow = (e) => {
		document.querySelector("body").style.overflow =
			document.body.clientWidth > 991 ? "hidden" : ""
	}
	useEffect(() => {
		window.addEventListener("resize", disableOverflow)

		return () => {
			window.removeEventListener("resize", disableOverflow)
		}
	}, [])

	return (
		<>
			<div className="section-wrapper home">
				{/* Main Content Starts */}
				<section className="d-flex align-items-center min-vh-100 container-fluid main-container container-home p-0">
					<div className="color-block d-none d-lg-block" />
					<Row className="home-details-container align-items-center">
						<Col sm={12} className="d-block d-sm-none">
							<img
								src={require("../Assets/img/img-mobile.jpg")}
								className="img-fluid main-img-mobile"
								alt=""
							/>
						</Col>
						<Col
							lg={4}
							className="bg position-fixed d-none d-lg-block"
						/>
						<Col
							sm={12}
							lg={8}
							className="offset-lg-4 home-details text-start text-lg-start text-sm-center"
						>
							<div>
								<img
									src="../Assets/img/img-mobile.jpg"
									className="img-fluid main-img-mobile d-none d-sm-block d-lg-none"
									alt=""
								/>
								<h1 className="text-uppercase poppins-font">
									I'm Amirreza Malaki.
									<span>Web Developer</span>
								</h1>
								<p className="open-sans-font">
									I'm a full-stack web developer with a decade
									of experience.
									<br />I also teach web development and
									JavaScript online.
								</p>
								<Link className="button" to="/about">
									<span className="button-text">
										more about me
									</span>
									<FontAwesomeIcon
										className="button-icon"
										icon={faArrowRight}
									/>
								</Link>
							</div>
						</Col>
					</Row>
				</section>
				{/* Main Content Ends */}
			</div>
		</>
	)
}

export default Home
