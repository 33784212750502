import { createContext, useContext, useState } from "react"

const MainContent = createContext()

export function useMain() {
	return useContext(MainContent)
}

const MainComponent = ({ children, Navigations }) => {
	const [currentSlideShow, setCurrentSlideShow] = useState(-1)
	return (
		<MainContent.Provider
			value={{
				Navigations,
				currentSlideShow,
				setCurrentSlideShow,
			}}
		>
			{children}
		</MainContent.Provider>
	)
}

export default MainComponent
